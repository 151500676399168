import React from 'react'

import Container from '../components/Container'
import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/Layout'

import Link from '../components/Link'
import {css} from '@emotion/core'

const Kontakt = () => {
 
    const data = useStaticQuery(graphql`
    query {
        site {
          ...site
          siteMetadata {
            title
          }
        }
    }
    
    `)
    return(
        <Layout site={data.site}>

        <Container css={css`
          h1{
            font-size:1.5rem;
          }
          img {
            width:500px;
            height:auto;
            padding-bottom:40px;
          }
          p {
            font-size:1rem;
          
          }
          @media(max-width:360px){
            p {
              font-size:.7rem;
            }
            img {
              width:300px;
              height:auto;
              padding-bottom:30px;
            }
            h1 {
              font-size:1.3rem;
            }
          }
        `}>
      
         

     
    
  
     <h1>
        Kulturraum Markt 1
     </h1>
     <p>verantwortlich: <br/>Verschönerungsverein Bacharach 1873 e.V.<br/>
     55422 Bacharach<br/>
     Marktstraße 3</p>
     <br/><br/>
     
     
     <p>Ansprechpartnerin:<br/> Katrin Gloggengießer<br/>
    kontakt[at]markt1-bacharach.de
     </p>
     <br/>
     <p><Link to="/datenschutz">&rarr; Datenschutzbestimmungen</Link></p>
    
        </Container>
        </Layout>

    )
}

export default Kontakt
